<template>
    <div>
        <div class="wrapper">
            <thin-navbar/>
            <header class="d-flex">
                <div class="m-auto text-center">
                    <router-link :to="{name: 'homepage'}">
                        <img src="/img/logo.png" alt="Cluji" class="main-logo"/>
                    </router-link>
                    <br/>
                    <h1 class="m-auto">
                        Club Jeux et Imaginaire de l'INSA Lyon
                    </h1>
                </div>
            </header>

            <div class="pt-5 pb-5">
                <router-view/>
            </div>
        </div>
        <footer class="d-flex">
            <b-container class="m-auto">
                <router-link :to="{name: 'homepage'}"> Accueil</router-link>
                &middot;
                <router-link :to="{name: 'cgu'}"> Conditions générales d'utilisation et mentions légales
                </router-link>
                <span class="float-right">
                    Site web réalisé par <a href="https://julien-emmanuel.com" target="_blank">Julien EMMANUEL</a>
                </span>
            </b-container>
        </footer>
    </div>
</template>

<script>
    const ThinNavbar = () => import('@/components/layout/ThinNavbar');

    export default {
        name: "ClassicTemplate",
        components: {ThinNavbar}
    }
</script>

<style scoped>
    header {
        height: 400px;
        max-height: 100vh;
        /* Linear gradient darkens the image */
        background: linear-gradient(rgba(0, 0, 0, 0.66), rgba(0, 0, 0, 0.66)), url("/img/background.jpg") no-repeat center;
        background-size: cover;
    }

    h1 {
        font-size: 40px;
        color: white;
        text-shadow: black 0 0 5px;
        font-weight: lighter;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    }

    img.main-logo {
        width: 200px;
        filter: drop-shadow(0 0 0.25rem black);
    }

    footer {
        font-size: small;
        background-color: #eee;
        height: 64px;
    }

    .wrapper {
        min-height: calc(100vh - 64px); /* Allows the footer to stay at the bottom of pages, even small ones */
        background: white;
    }
</style>
